const MovEllipseBig = (
    <svg
        width="1682"
        height="1682"
        viewBox="0 0 1682 1682"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.15" filter="url(#filter0_f_2_27)">
            <circle cx="841" cy="841" r="341" fill="#9414FF" />
        </g>
        <defs>
            <filter
                id="filter0_f_2_27"
                x="0"
                y="0"
                width="1682"
                height="1682"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="250"
                    result="effect1_foregroundBlur_2_27"
                />
            </filter>
        </defs>
    </svg>
);

export default MovEllipseBig;
