/**
 * A utility class for managing cookies.
 * Cookies are stored as key-value pairs, where the key is a string and the value is a string or object.
 * If an object is provided as the value, it will be converted to a string using JSON.stringify().
 * @category Services > Storage > Cookie Manager Service
 * @class CookieManager
 * @example
 * const cookieManager = new CookieManager(); // Creates a new instance of CookieManager with the default expiration time of 30 days.
 */
class CookieManager {
    /**
     * Creates an instance of CookieManager.
     * @param {number} [exp=30] - The expiration time for the cookies in days. Defaults to 30 days.
     */
    constructor(exp = 30) {
        this.expiration = exp;
    }

    /**
     * Creates a new cookie or updates an existing cookie with the provided key, value, and expiration.
     * @param {string} key - The key or identifier for the cookie.
     * @param {string|Object} value - The value to store in the cookie. If an object is provided, it will be converted to a string using JSON.stringify().
     * @param {number} [exp] - The expiration time for the cookie in days. If not specified, the default expiration time set during initialization will be used.
     */
    set(key, value, exp = this.expiration) {
        if (typeof value !== "string" || !Number(value)) value = JSON.stringify(value);

        let date = new Date();
        date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
        const expires = "; expires=" + date.toGMTString();
        document.cookie = key + "=" + value + expires + "; path=/";
    }

    /**
     * Retrieves the value of the cookie associated with the provided key.
     * @param {string} key - The key or identifier for the cookie.
     * @returns {string|Object|null} The value of the cookie, or null if the cookie does not exist.
     */
    get(key) {
        let value = "";
        const nameEQ = key + "=";
        const ca = document.cookie.split(';');
        for (let i = 0, max = ca.length; i < max; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) === 0) {
                try {
                    value = JSON.parse(c.substring(nameEQ.length, c.length));
                } catch (e) {}

                return value;
            }
        }
        return null;
    }

    /**
     * Removes the cookie associated with the provided key.
     * @param {string} key - The key or identifier for the cookie.
     */
    remove(key) {
        document.cookie = key + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
}

export default CookieManager;
