import { Link } from "react-router-dom";

import styles from "./style.module.scss";
import Button from "Atoms/Buttons/Button";

const ResourcesDropdown = ({ pages, additionalContent }) => {
    return (
        <section className={styles.resourcesDropdown}>
            <ul className={styles.links}>
                {pages?.map(({ title, content, path }) => (
                    <li key={path} className={styles.item}>
                        <Link
                            to={path}
                            name={`pageLink-${path}`}
                            aria-label={title}
                            title={title}
                            className={styles.title}
                        >
                            {title}
                        </Link>
                        <p className={styles.content}>{content}</p>
                    </li>
                ))}
            </ul>
            <aside className={styles.additionalContent}>
                <div className={styles.imageContainer}>
                    <img
                        alt={additionalContent?.image?.alt}
                        src={additionalContent?.image?.src}
                    />
                </div>
                <h4 className={styles.additionalContentTitle}>
                    {additionalContent?.title}
                </h4>
                <h4 className={styles.additionalContentContent}>
                    {additionalContent?.content}
                </h4>
                <Button
                    customStyle={{
                        padding: "8px 20px",
                        borderRadius: "6px",
                        justifyContent: "center",
                        fontSize: "14px",
                        fontWight: "500",
                    }}
                >
                    {additionalContent?.textButton}
                </Button>
            </aside>
        </section>
    );
};

export default ResourcesDropdown;
