export const termsOfServicesConfig = {
    SEO_DATA: {
        title: "MeetusVR | Terms of Service",
        description:
            "Discover the latest insights and developments in the metaverse and blockchain technology with MeetusVR. " +
            "Stay informed about virtual reality, decentralized finance, NFTs, and more.",
        keywords: [
            "MeetusVR",
            "Metaverse",
            "Virtual Reality",
            "VR",
            "Blockchain",
            "NFTs",
            "Decentralized Finance",
            "DeFi",
        ],
    },
    title: "TERMS OF SERVICE",
    date: "September 25th, 2023",
    description:
        "Please carefully read these Terms of Service (the “Agreement”) before using the MeetUsVR services (the “Services”) provided by MeetUsVR Inc. (“MeetUsVR,” “we,” “us,” or “our”). Your access to and use of the Services are conditioned upon your acceptance of and compliance with this Agreement. This Agreement applies to all users and visitors who wish to access or use the Services. By accessing or using the Services, you agree to be bound by this Agreement. If you disagree with any part of this Agreement, you do not have permission to access the Services.",
    mainPoints: [
        {
            title: "Definitions",
            subPoints: [
                {
                    title: "a. Activation Link: ",
                    description:
                        "The link through which a user may sign up and make payments to receive Services.",
                },
                {
                    title: "b. Customer Content: ",
                    description:
                        "Content, data, and information submitted to the Services by the user or a third party on behalf of or for the benefit of the user.",
                },
                {
                    title: "c. Documentation: ",
                    description:
                        "MeetUsVR’s available documentation, specifications, user manuals, and more, accessible at www.meetusvr.com or other provided URLs.",
                },
                {
                    title: "d. Order Form: ",
                    description:
                        "The document describing the Services and pricing purchased by the user, which incorporates these Terms of Service.",
                },
                {
                    title: "e. Services: ",
                    description: "Products and services provided by MeetUsVR.",
                },
                {
                    title: "f. Subscription Term: ",
                    description:
                        "The term for the Services as specified in the applicable Order Form or Activation Link.",
                },
                {
                    title: "g. User: ",
                    description:
                        "An individual who is authorized by the user to use the Services.",
                },
            ],
        },
        {
            title: "General Commercial Terms",
            subPoints: [
                {
                    title: "a. Access: ",
                    description:
                        "During the Subscription Term, we will provide you with access to the Services.",
                },
                {
                    title: "b. Fees and Payment: ",
                    description:
                        "You agree to pay all applicable fees for the Services as set forth in the invoice. Payments are final and non-refundable. If payments are made via invoice, full payment must be received within thirty (30) days from the invoice date.",
                },
                {
                    title: "c. Subscription Fees and Payment: ",
                    description:
                        "Payment via credit card authorizes us to charge your account for all fees during the Subscription Term. Payment via invoice will follow invoicing terms.",
                },
                {
                    title: "d. Availability: ",
                    description:
                        "MeetUsVR will make commercially reasonable efforts to maintain a minimum uptime percentage of 99.5% during monthly billing periods.",
                },
                {
                    title: "e. Warranties: ",
                    description:
                        "MeetUsVR represents that the Services will be provided professionally and in accordance with industry standards.",
                },
            ],
        },
        {
            title: "Use and Limitations of Use",
            subPoints: [
                {
                    title: "a. Acceptable Use: ",
                    description:
                        "Users must comply with MeetUsVR’s Acceptable Use Policy.",
                },
                {
                    title: "b. Prohibited and Unauthorized Use: ",
                    description:
                        "Users shall not make the Services available to anyone other than authorized users or use them for illegal purposes. Prohibited actions include sending SPAM, storing or transmitting infringing or unlawful content, interfering with service integrity, attempting unauthorized access, copying the Services, and more.",
                },
                {
                    title: "c. No Sensitive Information: ",
                    description:
                        "Users agree not to use the Services to collect, manage, or process sensitive personal information.",
                },
                {
                    title: "d. Subscription Term and Termination: ",
                    description:
                        "Terms of Subscription and Termination are detailed in the Agreement.",
                },
            ],
        },
        {
            title: "General Legal Terms",
            subPoints: [
                {
                    title: "a. Customer Content: ",
                    description:
                        "Users are responsible for their Customer Content. MeetUsVR takes no responsibility for third-party content.",
                },
                {
                    title: "b. MeetUsVR Proprietary Rights: ",
                    description:
                        "MeetUsVR retains all rights to the Services and its intellectual property.",
                },
                {
                    title: "c. Customer Proprietary Rights: ",
                    description: "Users retain all rights to their Customer Content.",
                },
                {
                    title: "d. Indemnification: ",
                    description:
                        "MeetUsVR will indemnify users against claims of intellectual property infringement.",
                },
                {
                    title: "e. Confidentiality: ",
                    description:
                        "Both parties agree to maintain the confidentiality of information shared during the use of the Services.",
                },
                {
                    title: "f. Publicity: ",
                    description:
                        "Users grant MeetUsVR the right to use their name and company logo for customer lists and promotional purposes.",
                },
                {
                    title: "g. Disclaimers: ",
                    description: `Except as provided, the Services are provided "as is." MeetUsVR makes no warranties except as expressly provided.`,
                },
                {
                    title: "h. Limitation of Liability: ",
                    description:
                        "MeetUsVR’s liability is limited to the total amounts paid by the user in the twelve-month period preceding the claim.",
                },
            ],
        },
        {
            title: "General Provisions",
            subPoints: [
                {
                    title: "a. Force Majeure: ",
                    description:
                        "Neither party is responsible for failure or delay of performance due to unforeseeable circumstances.",
                },
                {
                    title: "b. Relationship of the Parties: ",
                    description:
                        "This Agreement does not create a partnership, employment, or agency relationship between the parties.",
                },
                {
                    title: "c. Compliance with Laws: ",
                    description: "Both parties agree to comply with applicable laws.",
                },
                {
                    title: "d. No Waiver: ",
                    description:
                        "No waiver of rights will be considered unless provided in writing.",
                },
                {
                    title: "e. Severability: ",
                    description:
                        "Invalidation of any part of this Agreement will not affect the remainder.",
                },
                {
                    title: "f. Notices: ",
                    description: "All notices must be sent in writing.",
                },
                {
                    title: "g. Entire Agreement: ",
                    description: "This Agreement supersedes all prior agreements.",
                },
                {
                    title: "h. Assignment: ",
                    description:
                        "Neither party may assign rights or obligations without written consent.",
                },
                {
                    title: "i. No Third-Party Beneficiaries: ",
                    description: "No third party benefits from this Agreement.",
                },
                {
                    title: "j. Authority: ",
                    description:
                        "Both parties have the authority to enter this Agreement.",
                },
                {
                    title: "k. Precedence: ",
                    description: "In case of conflict, the Order Form controls.",
                },
                {
                    title: "l. Governing Law: ",
                    description:
                        "This Agreement is governed by the laws of Massachusetts.",
                },
                {
                    title: "m. Changes: ",
                    description:
                        "MeetUsVR reserves the right to modify these Terms of Service at its discretion",
                },
            ],
        },
    ],
};
