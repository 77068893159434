import FooterBackground from "Utils/Assets/Images/footerBackground.png";
import FooterBackgroundForMobile from "Utils/Assets/Images/FooterMob.png";
import TwitterIcon from "Utils/Assets/Icons/TwitterIcon";

import { getProperImageUrl } from "Utils/Helpers";

export const config = {
  footerLogo: getProperImageUrl("4/footerlogo.svg"),
  text: "MeetusVR: A technology-driven company headquartered in Lithuania, specializing in blockchain and mobile payment solutions. Dedicated to innovation, MeetusVR focuses on providing immersive virtual experiences and secure digital transaction services, supporting growth and advancement in the global tech landscape.",
  footerBackground: FooterBackground,
  footerBackgroundForMobile: FooterBackgroundForMobile,
  menus: [
    {
      id: 1,
      title: "Company",
      linkType: "internal",
      menuLinks: [
        [
          {
            id: 1,
            text: "Home",
            path: "/",
          },
          {
            id: 2,
            text: "Pricing",
            path: "/pricing",
          },
          {
            id: 3,
            text: "Blog",
            path: "/blog",
          },
          {
            id: 4,
            text: "Contact us",
            path: "/contact-us",
          },
          {
            id: 5,
            text: "Event",
            path: "/event",
          },
          {
            id: 5,
            text: "Download app",
            path: "/download-app",
          },
        ],
      ],
    },
    {
        id: 2,
        title: "Features",
        menuLinks: [
            [
                {
                    id: 1,
                    text: "360 technology",
                    path: "/360-tech",
                },
                {
                    id: 2,
                    text: "Personalized Assistance",
                    path: "/personalized-assistance",
                },
                {
                    id: 3,
                    text: "Influencer Opportunities",
                    path: "/influencer-opportunities",
                },
                {
                    id: 4,
                    text: "Data-Driven Optimization",
                    path: "/data-driven-optimization",
                },
            ],
        ],
        linkType: "internal",
    },
    {
      id: 3,
      title: "Follow Us",
      menuLinks: [
        [
          {
            id: 1,
            text: "Discord",
            path: "/",
            Icon: TwitterIcon,
          },
          {
            id: 2,
            text: "Twitter",
            path: "/",
            Icon: TwitterIcon,
          },
          {
            id: 3,
            text: "LinkedIn",
            path: "/",
            Icon: TwitterIcon,
          },
        ],
      ],
      linkType: "external",
      socialMediaLinks: true,
    },
  ],
  subFooter: {
    text: `Copyright © MeetUsVR ${new Date()?.getFullYear()} All Right Reserved`,
    links: [
      {
        id: 1,
        text: "Privacy Policy",
        path: "/privacy-policy",
      },
      {
        id: 2,
        text: "Terms of Service",
        path: "/terms-of-services",
      },
      {
        id: 3,
        text: "API Terms of Service",
        path: "/api-terms-of-services",
      },
    ],
  },
};
