export const privacyPolicyConfig = {
    SEO_DATA: {
        title: "MeetusVR | Privacy Policy",
        description:
            "Discover the latest insights and developments in the metaverse and blockchain technology with MeetusVR. " +
            "Stay informed about virtual reality, decentralized finance, NFTs, and more.",
        keywords: [
            "MeetusVR",
            "Metaverse",
            "Virtual Reality",
            "VR",
            "Blockchain",
            "NFTs",
            "Decentralized Finance",
            "DeFi",
        ],
    },
    title: "PRIVACY POLICY",
    date: "September 25th, 2023",
    description: `This Privacy Policy describes how MeetUsVR Inc. ("MeetUsVR," "we," "us," or "our") collects, uses, shares, and protects your personal information when you use the MeetUsVR services (the “Services”). By accessing or using the Services, you agree to the terms of this Privacy Policy.`,
    mainPoints: [
        {
            title: "Information We Collect",
            subPoints: [
                {
                    title: "a. Personal Information: ",
                    description:
                        "We may collect personal information, including but not limited to, your name, email address, contact information, and payment details when you sign up for the Services or communicate with us.",
                },
                {
                    title: "b. Usage Information: ",
                    description:
                        "We collect information about your usage of the Services, including your interactions with other users, virtual spaces, and content.",
                },
                {
                    title: "c. Documentation: ",
                    description:
                        "MeetUsVR’s available documentation, specifications, user manuals, and more, accessible at www.meetusvr.com or other provided URLs.",
                },
                {
                    title: "d. Device and Log Information: ",
                    description:
                        "We may collect information about your device, browser type, operating system, IP address, and log files generated by your use of the Services.",
                },
            ],
        },
        {
            title: "How We Use Your Information",
            subPoints: [
                {
                    title: "a. To Provide Services: ",
                    description:
                        "We use your information to provide and maintain the Services, including creating and managing your account, facilitating communication between users, and processing payments.",
                },
                {
                    title: "b. To Improve Services: ",
                    description:
                        "We may use your information to analyze user behavior and preferences, troubleshoot technical issues, and improve the Services.",
                },
                {
                    title: "c. To Communicate: ",
                    description:
                        "We may use your information to send you notifications, updates, and marketing communications, as permitted by applicable law.",
                },
            ],
        },
        {
            title: "Sharing Your Information",
            subPoints: [
                {
                    title: "a. With Other Users: ",
                    description:
                        "Certain information, such as your username and profile picture, may be visible to other users within the Services.",
                },
                {
                    title: "b. With Service Providers: ",
                    description:
                        "We may share your information with third-party service providers who assist us in delivering the Services.",
                },
                {
                    title: "c. For Legal Compliance: ",
                    description:
                        "We may disclose your information to comply with legal obligations, protect our rights, and ensure the safety of our users.",
                },
            ],
        },
        {
            title: "Your Choices",
            subPoints: [
                {
                    title: "a. Account Information: ",
                    description:
                        "You may review and update your account information by logging into your account.",
                },
                {
                    title: "b. Marketing Communications: ",
                    description:
                        "You can opt out of receiving marketing communications from us by following the instructions provided in the communication.",
                },
            ],
        },
        {
            title: "Security",
            subPoints: [
                {
                    description:
                        "We take reasonable measures to protect your information, but no data transmission over the internet is entirely secure. Therefore, we cannot guarantee the security of your information.",
                },
            ],
        },
        {
            title: "International Users",
            subPoints: [
                {
                    description:
                        "The Services are hosted in the United States. If you are using the Services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States.",
                },
            ],
        },
        {
            title: "Changes to this Privacy Policy",
            subPoints: [
                {
                    description:
                        "We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Your continued use of the Services after changes become effective constitutes your acceptance of the revised Privacy Policy.",
                },
            ],
        },
        {
            title: "Contact Us",
            subPoints: [
                {
                    description:
                        "If you have any questions or concerns about this Privacy Policy, please contact us at [contact@email.com].",
                },
            ],
        },
    ],
};
