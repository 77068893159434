import PropTypes from "prop-types";

const EllipseBlur = ({
    width,
    height,
    borderRadius,
    opacity,
    background,
    blur,
}) => {
    return (
        <div
            style={{
                width: width,
                height: height,
                borderRadius: borderRadius,
                opacity: opacity,
                background: background,
                filter: `blur(${blur})`,
                WebkitFilter: `blur(${blur})`,
                transform: "translate3d(0, 0, 0)"
            }}
        />
    );
};

EllipseBlur.defaultProps = {
    width: "682px",
    height: "682px",
    borderRadius: "50%",
    opacity: "0.33",
    background: "#FF005B",
    blur: "250px",
};

EllipseBlur.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    borderRadius: PropTypes.string,
    opacity: PropTypes.string,
    background: PropTypes.string,
    blur: PropTypes.string,
};

export default EllipseBlur;
