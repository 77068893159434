import { Suspense } from "react";
import PropTypes from "prop-types";

import MeetUsWrapper from "../Components/MeetUsWrapper";
import EllipseBlur from "../Atoms/EllipseBlur";
import MeetusVRLogo3D from "Utils/Assets/Icons/MeetusVRLogo3D";
import MeetusTitle from "../Utils/Assets/Icons/MeetusTitle";

import styles from "./style.module.scss";

const LazyFallback = () => (
    <MeetUsWrapper>
        <main className={styles.lazyFallbackWrapper}>
            <MeetusVRLogo3D />
            <MeetusTitle />
        </main>

        <div className={styles.rightEllipse}>
            <EllipseBlur opacity="0.2" />
        </div>

        <div className={styles.leftEllipse}>
            <EllipseBlur background={"#9414FF"} opacity="0.3" />
        </div>
    </MeetUsWrapper>
);

export default function LazyWrapper({ children }) {
    return <Suspense fallback={<LazyFallback />}>{children}</Suspense>;
}

LazyWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};
