export const apiTermsConfig = {
    SEO_DATA: {
        title: "MeetusVR | API Terms of Service",
        description:
            "Discover the latest insights and developments in the metaverse and blockchain technology with MeetusVR. " +
            "Stay informed about virtual reality, decentralized finance, NFTs, and more.",
        keywords: [
            "MeetusVR",
            "Metaverse",
            "Virtual Reality",
            "VR",
            "Blockchain",
            "NFTs",
            "Decentralized Finance",
            "DeFi",
        ],
    },
    title: "API TERMS OF SERVICE",
    date: "September 25th, 2023",
    mainPoints: [
        {
            title: "Acceptance of Terms",
            subPoints: [
                {
                    description: `Thank you for using the MeetUsVR API ("API") provided by MeetUsVR ("Us", "Us", or "Us"). Your use of this API is subject to the following terms and conditions. By accessing or using our API, you automatically agree to comply with these terms. If you do not agree to these terms, please do not use our API.`,
                },
            ],
        },
        {
            title: "How We Use Your Information",
            subPoints: [
                {
                    title: "a. To Provide Services: ",
                    description:
                        "We use your information to provide and maintain the Services, including creating and managing your account, facilitating communication between users, and processing payments.",
                },
                {
                    title: "b. To Improve Services: ",
                    description:
                        "We may use your information to analyze user behavior and preferences, troubleshoot technical issues, and improve the Services.",
                },
                {
                    title: "c. To Communicate: ",
                    description:
                        "We may use your information to send you notifications, updates, and marketing communications, as permitted by applicable law.",
                },
            ],
        },
        {
            title: "Use License",
            subTitle: "Below is a limited license to use our API:",
            withBullets: true,
            subPoints: [
                {
                    description:
                        "You are granted a limited, non-exclusive, non-transferable, revocable right to access and use our API for the purpose of integrating our services with your own applications, provided you comply with these terms.",
                },
                {
                    description:
                        "You agree that we may revoke your right to use our API at any time without notice if we discover a violation of this provision.",
                },
            ],
        },
        {
            title: "Usage Restrictions",
            subTitle: "In using our API, you are NOT ALLOWED to:",
            withBullets: true,
            subPoints: [
                {
                    description: "Use our API for illegal or unlawful purposes.",
                },
                {
                    description:
                        "Copy, distribute or upload our API to other platforms without our express permission.",
                },
                {
                    description:
                        "Make excessive or disruptive API requests, or access our APIs in a way that could damage our services or affect other users.",
                },
            ],
        },
        {
            title: "User Data",
            subPoints: [
                {
                    description:
                        "By using our API, you may have access to or collect user data. You must comply with all applicable privacy laws and protect user data in accordance with appropriate security standards. We are not responsible for any user data you access or collect through our API.",
                },
            ],
        },
        {
            title: "Changes in API",
            subPoints: [
                {
                    description:
                        "We reserve the right to change, modify, or terminate our API at any time, including any features, functionality, or terms of use, without prior notice. Any changes will be notified via our API documentation page.",
                },
            ],
        },
        {
            title: "Support and Contact",
            subPoints: [
                {
                    description:
                        "We will endeavor to provide reasonable technical support regarding the use of our API. For questions or concerns, contact us at [contact email address] or visit our [contact page].",
                },
            ],
        },
        {
            title: "Contact Us",
            subPoints: [
                {
                    description:
                        "If you have any questions or concerns about this Privacy Policy, please contact us at [contact@email.com].",
                },
            ],
        },
        {
            title: "Closing",
            subPoints: [
                {
                    description:
                        "We reserve the right to terminate your access to our API if we discover a violation of these terms. You can also end use of our API at any time by discontinuing use and deleting all our data from your application.",
                },
            ],
        },
        {
            title: "Applicable Law",
            subPoints: [
                {
                    description: (
                        <>
                            These Terms are governed by and subject to the laws applicable in
                            [your jurisdiction]. You agree to resolve any disputes arising
                            from or relating to these provisions in a court of competent
                            jurisdiction in [your jurisdiction].
                            <br />
                            <br />
                            Thank you for using the MeetUsVR API. We hope you can make the
                            most of it.
                        </>
                    ),
                },
            ],
        },
    ],
};
