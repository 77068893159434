const TwitterIcon = (props) => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.333313 10C0.333313 4.47715 4.81047 0 10.3333 0C15.8562 0 20.3333 4.47715 20.3333 10C20.3333 15.5228 15.8562 20 10.3333 20C4.81047 20 0.333313 15.5228 0.333313 10ZM10.0338 8.12812L10.0548 8.47415L9.70506 8.43178C8.43201 8.26936 7.31983 7.71855 6.37554 6.79347L5.91388 6.33446L5.79497 6.67342C5.54316 7.42902 5.70404 8.22699 6.22865 8.76368C6.50844 9.06027 6.44549 9.10264 5.96284 8.9261C5.79497 8.8696 5.64808 8.82723 5.63409 8.84842C5.58513 8.89785 5.753 9.54046 5.8859 9.79468C6.06777 10.1478 6.43849 10.4938 6.84419 10.6986L7.18693 10.861L6.78124 10.8681C6.38953 10.8681 6.37554 10.8751 6.41751 11.0234C6.5574 11.4824 7.10999 11.9697 7.72553 12.1815L8.15921 12.3298L7.78149 12.5558C7.22191 12.8806 6.5644 13.0642 5.90689 13.0784C5.59212 13.0854 5.33331 13.1137 5.33331 13.1349C5.33331 13.2055 6.18668 13.6009 6.68331 13.7563C8.1732 14.2153 9.94288 14.0176 11.2719 13.2337C12.2162 12.6758 13.1605 11.5672 13.6012 10.4938C13.839 9.92179 14.0768 8.87666 14.0768 8.37528C14.0768 8.05045 14.0978 8.00808 14.4895 7.61968C14.7203 7.39371 14.9372 7.14655 14.9791 7.07593C15.0491 6.94176 15.0421 6.94176 14.6854 7.06181C14.0908 7.27366 14.0069 7.24541 14.3006 6.92764C14.5175 6.70166 14.7763 6.29209 14.7763 6.17204C14.7763 6.15085 14.6714 6.18616 14.5525 6.24972C14.4265 6.32033 14.1468 6.42626 13.9369 6.48981L13.5592 6.60986L13.2164 6.37683C13.0276 6.24972 12.7618 6.10848 12.6219 6.06611C12.2652 5.96725 11.7196 5.98137 11.3978 6.09436C10.5234 6.41214 9.97086 7.23129 10.0338 8.12812Z"
                fill="white"
            />
        </svg>
    );
};

export default TwitterIcon;
