import { useCallback, useEffect, useRef, useState } from "react";
import useGetUserData from "Utils/Hooks/Custom/useGetUserData";
import { useClickOutsideElement } from "Utils/Hooks/Custom/useClickOutsideElement";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import MeetUsWrapper from "../MeetUsWrapper";
import Button from "Atoms/Buttons/Button";
import Image from "Components/Global/Image";
import FeaturesDropdown from "./FeaturesDropdown";
import ResourcesDropdown from "./ResourcesDropdown";

import MovEllipseBig from "Utils/Assets/Icons/MovEllipseBig";
import RedEllipseBig from "Utils/Assets/Icons/RedEllipseBig";
import { AiOutlineClose } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import arrowDown from "Utils/Assets/Icons/arrow_down";

import { logout } from "Utils/Hooks/Global/logout";
import appConfig from "config.json";
import { navbarConfig } from "./config";
import { getProperImageUrl } from "Utils/Helpers";
import API from "Utils/API/Backend";

import styles from "./style.module.scss";

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: "0.5" },
};

const dropdownContent = ({ page, isLargeScreen, handleTriggerNav }) => {
  if (isLargeScreen) {
    return (
      <ul className={styles.dropDown}>
        {page?.children?.map((child) => (
          <li key={child?.path}>
            <Link
              to={child?.path}
              name={`dropdownLink-${child?.path}`}
              aria-label={child?.title}
              title={child?.title}
              className={styles.dropdownLink}
              onClick={handleTriggerNav}
            >
              <span className={styles.dot}></span>
              <p className={styles.text}>{child?.title}</p>
            </Link>
          </li>
        ))}
      </ul>
    );
  } else {
    if (page?.id === 1) {
      return (
        <div className={styles.dropDown}>
          <FeaturesDropdown pages={page?.children} />
        </div>
      );
    } else if (page?.id === 2) {
      return (
        <div className={`${styles.dropDown} ${styles.resources}`}>
          <ResourcesDropdown
            pages={page?.children}
            additionalContent={page?.additionalContent}
          />
        </div>
      );
    } else return "";
  }
};
const Navbar = () => {
  const { links } = navbarConfig;
  const { token } = useGetUserData();
  const navigate = useNavigate();

  const navRef = useRef(null);
  const firstDropDownRef = useRef(null);
  const secondDropDownRef = useRef(null);
  const [openNav, setOpenNav] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [subscriptionDetails ,setSubscriptionDetails] = useState({});

  const handleTriggerNav = useCallback(() => {
    setOpenNav((prevState) => !prevState);
  }, []);

  const handleClickOutSideNav = useCallback(() => {
    setOpenNav(false);
    setOpenDropDown(null);
  }, []);

  const handleClickOutSideDropDown = useCallback(() => {
    setOpenDropDown(null);
  }, []);

  const handleDropDown = useCallback(
    (id) => {
      if (openDropDown === id) {
        setOpenDropDown(null);
      } else {
        setOpenDropDown(id);
      }
    },
    [openDropDown]
  );

  useClickOutsideElement(
    [navRef, firstDropDownRef, secondDropDownRef],
    () => openNav && handleClickOutSideNav()
  );
  useClickOutsideElement(
    [firstDropDownRef, secondDropDownRef],
    () => openDropDown && handleClickOutSideDropDown()
  );

  const dropdownRef = (hasDropDown, id) => {
    if (hasDropDown) {
      if (id === 1) {
        return firstDropDownRef;
      } else {
        return secondDropDownRef;
      }
    } else return null;
  };

  useEffect(() => {
    const checkMobile = () => {
      const isLargeScreenDevice = window.matchMedia(
        "(max-width: 1200px)"
      ).matches;
      setIsLargeScreen(isLargeScreenDevice);
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    if (!isLargeScreen) {
      navRef.current.style.marginTop = `${100}px`;
      return;
    }

    if (openNav) {
      navRef.current.style.marginTop = `${0}px`;
    } else {
      navRef.current.style.marginTop = `${58}px`;
    }
    const handleScroll = () => {
      if (window.scrollY === 0) {
        navRef.current.style.marginTop = `${58}px`;
      } else {
        navRef.current.style.marginTop = `${0}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLargeScreen, openNav]);

  useEffect(() => {
    API({
      endpoint: "subscription/info",
    })
    .then((res) => {
      setSubscriptionDetails(res?.data?.data);
    })
  }, []);

  // TODO: divide this file into smaller atoms to improve performance
  return (
    <MeetUsWrapper className={styles.meetUsWrapper}>
      <nav
        className={`${styles.navbarContainer} ${openNav ? styles.openNav : ""}`}
        ref={navRef}
      >
        <section className={styles.navbarSubContainer}>
          <div className={styles.navbarLogo}>
            <Link
              to="/"
              name="meetUsLink"
              aria-label="Navigate to the home page"
              title="Navigate to the home page"
              onClick={() => {
                openNav && handleTriggerNav();
              }}
            >
              <Image
                src={getProperImageUrl("4/meetus-logo.svg")}
                alt="meetUs vr"
                width={129}
                height={28}
              />
            </Link>
            <div
              className={styles.menuIcon}
              onClick={handleTriggerNav}
              onKeyDown={(e) =>
                (e.key === "m" || e.key === "M") && handleTriggerNav()
              }
            >
              {openNav ? <AiOutlineClose /> : <FiMenu />}
            </div>
          </div>
          <div className={styles.verticalLine} />
          <ul className={styles.navbarLinks}>
            {links.map((page) => {
              const { hasDropDown } = page;
              return (
                <li
                  onClick={() => hasDropDown && handleDropDown(page.id)}
                  onKeyDown={(e) =>
                    (e.key === "l" || e.key === "L") &&
                    hasDropDown &&
                    handleDropDown(page.id)
                  }
                  key={page.id}
                  ref={dropdownRef(hasDropDown, page?.id)}
                >
                  {hasDropDown ? (
                    <span
                      className={
                        openDropDown === page.id ? styles.activeDropdown : ""
                      }
                    >
                      {page.name} {arrowDown}
                    </span>
                  ) : (
                    <Link
                      to={page.path}
                      name={`pageLink-${page.path}`}
                      aria-label={`Navigate to ${page.name}`}
                      title={`Navigate to ${page.name}`}
                      onClick={handleTriggerNav}
                    >
                      {page.name}
                    </Link>
                  )}

                  {openDropDown === page.id && hasDropDown && (
                    <motion.div
                      initial={{
                        ...variants?.initial,
                      }}
                      animate={{
                        ...variants?.animate,
                      }}
                      exit={{
                        ...variants?.initial,
                      }}
                      transition={{
                        ...variants?.transition,
                      }}
                    >
                      {dropdownContent({
                        page,
                        isLargeScreen,
                        handleTriggerNav,
                      })}
                    </motion.div>
                  )}
                </li>
              );
            })}
          </ul>
        </section>

        <section className={styles.buttonsContainer}>
          {token && subscriptionDetails?.subscribed && (
            <Button
              name="dashboardButton"
              aria-label="Go to Dashboard button"
              withEllipses
              customStyle={{
                padding: "12px 20px",
                fontSize: 14,
                borderRadius: 8,
                fontWeight: 400,
                gap: 0,
              }}
              to={appConfig.config.dashboard}
              target={"_blank"}
              type={"a"}
            >
              Go to Dashboard
            </Button>
          )}

          {token ? (
            <Button
              name="logoutButton"
              aria-label="Logout button"
              customStyle={{
                padding: "12px 20px",
                fontSize: 14,
                borderRadius: 8,
                fontWeight: 400,
              }}
              onClick={logout}
            >
              Logout
            </Button>
          ) : (
            <>
              <Button
                name="connectWalletButton"
                aria-label="Login"
                title="Wallet Connect"
                withEllipses
                customStyle={{
                  padding: "12px 20px",
                  fontSize: 14,
                  borderRadius: 8,
                  fontWeight: 400,
                }}
                onClick={() => navigate("/login")}
              >
                Connect Wallet
              </Button>
              <Button
                name="loginButton"
                aria-label="Login"
                title="Navigate to Signup page"
                customStyle={{
                  padding: "12px 20px",
                  fontSize: 14,
                  borderRadius: 8,
                  fontWeight: 400,
                }}
                onClick={() => navigate("/register")}
              >
                Signup
              </Button>
            </>
          )}
        </section>

        <div className={styles.ellipseBig}>
          <div>
            {MovEllipseBig}
            {RedEllipseBig}
          </div>
        </div>
      </nav>
    </MeetUsWrapper>
  );
};

export default Navbar;
