import { useEffect, Fragment } from "react";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import appConfig from "./config.json";

import Layout from "./Layout";
import API from "./Utils/API/Backend";

export default function MeetusVRApp() {
    /**
     * @function getAppConfig - Get app config from backend and set it to appConfig object in config.json file in root directory.
     * @description - This function will get app config from backend and set it to appConfig an object in config.json file in root directory.
     * @returns {void} - Nothing to return here, but it will set appConfig object in config.json file.
     */
    useEffect(() => {
        async function getAppConfig() {
            const { data } = await API({
                endpoint: "frontend/setting?frontend_id=meetus_vr_website",
            });
            appConfig.config = { ...appConfig.config, ...data.data };
        }

        getAppConfig().then(() => {});
    }, []);

    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, []);

    return (
        <Fragment>
            <Layout />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={4}
            />
        </Fragment>
    );
}
