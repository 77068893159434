import SessionStorageManager from "../../Services/Storage/Session";
import CookieManager from "../../Services/Storage/Cookie";

/**
 * Asynchronously removes user data from a specified storage manager.
 * @module Utils/Hooks/Global/userCredentialsSaver
 * @category Global Hooks - User Credentials Saver Hook (userCredentialsSaver)
 */
export const removeUserData = () => {
    const sessionManager = new SessionStorageManager();
    const cookieManager = new CookieManager();

    const removeSessionData = () => {
        sessionManager.remove("U_K");
        sessionManager.remove("EMP");
        sessionManager.remove("ORG");
        sessionManager.remove("WAL_DATA");
    };

    const removeCookieData = () => {
        cookieManager.remove("U_K");
        cookieManager.remove("EMP");
        cookieManager.remove("ORG");
        sessionManager.remove("WAL_DATA");
    };

    // Attempt to retrieve user data from session storage
    let userToken = sessionManager.get("U_K");

    if (userToken) {
        removeSessionData();
    } else {
        removeCookieData();
    }

    window.location.reload();
}

/**
 * Asynchronously removes user data from a specified storage manager.
 * @module Utils/Hooks/Global/userCredentialsSaver
 * @category Global Hooks - User Credentials Saver Hook (userCredentialsSaver)
 * @returns {Promise} - Returns a promise that resolves to the removed key.
 * @throws {Promise} - Throws a promise that rejects with an error.
 * @example
 * removeWalletData();
 */
export const removeWalletData = () => {
    const sessionManager = new SessionStorageManager();
    const cookieManager = new CookieManager();

    const removeSessionData = () => {
        sessionManager.remove("WAL_DATA");
    };

    const removeCookieData = () => {
        cookieManager.remove("WAL_DATA");
    };

    // Attempt to retrieve user data from session storage
    let userToken = sessionManager.get("U_K");

    if (userToken) {
        removeSessionData();
    } else {
        removeCookieData();
    }
}
