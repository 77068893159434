import SessionStorageManager from "../../Services/Storage/Session";
import CookieManager from "../../Services/Storage/Cookie";

/**
 * Custom hook to retrieve and remove user data from session storage or cookies.
 * @returns {Object} An object containing the user token, user data, and organization ID, and functions to remove the data.
 */
export default function useGetUserData() {
    const sessionManager = new SessionStorageManager();
    const cookieManager = new CookieManager();

    // Function to remove data from session storage
    const removeSessionData = () => {
        sessionManager.remove("U_K");
        sessionManager.remove("EMP");
        sessionManager.remove("ORG");
    };

    // Function to remove data from cookies
    const removeCookieData = () => {
        cookieManager.remove("U_K");
        cookieManager.remove("EMP");
        cookieManager.remove("ORG");
    };

    // Attempt to retrieve user data from session storage
    let userToken = sessionManager.get("U_K");
    let userData;
    let organizationId;

    if (userToken) {
        userData = sessionManager.get("EMP");
        organizationId = sessionManager.get("ORG");
    } else {
        // If user data does not exist in session storage, try retrieving it from cookies
        userToken = cookieManager.get("U_K");
        userData = cookieManager.get("EMP");
        organizationId = cookieManager.get("ORG");
    }

    // Separate token and organizationId from the userData object
    if (userData) {
        const { token, organization_id, ...restData } = userData;
        userData = restData;
    }

    // Function to remove user data
    const removeUserData = () => {
        if (userToken) {
            removeSessionData();
        } else {
            removeCookieData();
        }
    };

    return {
        token: userToken || null,
        data: userData || null,
        organizationId: organizationId || null,
        removeUserData,
    };
}
