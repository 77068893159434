const RedEllipseSmallLight = (
    <svg
        width="184"
        height="184"
        viewBox="0 0 184 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_f_0_31)">
            <circle cx="92" cy="92" r="22" fill="#FF005B" />
        </g>
        <defs>
            <filter
                id="filter0_f_0_31"
                x="0"
                y="0"
                width="184"
                height="184"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="35"
                    result="effect1_foregroundBlur_0_31"
                />
            </filter>
        </defs>
    </svg>
);

export default RedEllipseSmallLight;
