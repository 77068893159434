import { Link } from "react-router-dom";

import MeetUsWrapper from "Components/MeetUsWrapper";
import Menu from "./Menu";
import EllipseBlur from "Atoms/EllipseBlur";
import Image from "Components/Global/Image";

import { config } from "./config";

import styles from "./style.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <MeetUsWrapper>
        <main
          className={styles.content}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className={styles.left}>
            <Image src={config?.footerLogo} alt="Logo" />
            <p>{config?.text}</p>
          </div>
          <div className={styles.right}>
            {config?.menus?.map((menu) => (
              <Menu key={menu?.id} menu={menu} />
            ))}
          </div>
        </main>
        <footer
          className={styles.subFooter}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <p>{config?.subFooter?.text}</p>
          <ul>
            {config?.subFooter?.links?.map((link) => (
              <li key={link?.id}>
                <Link
                  to={link?.path}
                  name={`subFooterLink-${link?.id}`}
                  aria-label={link?.text}
                  title={link?.text}
                >
                  {link?.text}
                </Link>
              </li>
            ))}
          </ul>
        </footer>
      </MeetUsWrapper>
      <Image
        src={config?.footerBackground}
        alt="Background"
        className={styles.footerBackground}
      />
      <Image
        src={config?.footerBackgroundForMobile}
        alt="Background"
        className={styles.footerBackgroundForMobile}
      />
      <div className={styles.redEllipse}>
        <EllipseBlur opacity="0.10" />
      </div>
      <div className={styles.movEllipse}>
        <EllipseBlur background="#9414FF" opacity="0.10" />
      </div>
    </footer>
  );
};

export default Footer;
