import PropTypes from "prop-types";
import "./style.scss";

const MeetUsWrapper = (props) => {
    return (
        <section id="meetUsWrapper" {...props}>
            {props?.children}
        </section>
    );
};

MeetUsWrapper.defaultProps = {
    className: "",
    children: null,
};

MeetUsWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default MeetUsWrapper;
