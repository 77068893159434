import PropTypes from "prop-types";

import RedEllipseSmallLight from "Utils/Assets/Icons/RedEllipseSmallLight";
import RedEllipseSmallDark from "Utils/Assets/Icons/RedEllipseSmallDark";

import styles from "./style.module.scss";

const Button = ({
    type,
    id,
    customStyle,
    onClick,
    disabled,
    children,
    StartIcon,
    EndIcon,
    isLoading,
    LoadingIndicator,
    gradient,
    withEllipses,
    ...rest
}) => {
    const content = isLoading ? LoadingIndicator : children;

    const {
        button: buttonStyles,
        gradient: gradientStyles,
        withEllipses: withEllipsesStyles,
    } = styles;

    return type !== "a" ? (
        <button
            className={`${buttonStyles} ${gradient ? gradientStyles : ""} ${
                withEllipses ? withEllipsesStyles : ""
            }`}
            type={type}
            id={id}
            disabled={disabled || isLoading}
            style={customStyle}
            onClick={onClick}
            {...rest}
        >
            {StartIcon && StartIcon}
            {withEllipses ? (
                <div>
                    <span className={styles.ellipsesText}>{content}</span>
                    <div className={styles.ellipsesContainer}>
                        {RedEllipseSmallLight}
                        {RedEllipseSmallDark}
                    </div>
                </div>
            ) : (
                content
            )}
            {EndIcon && EndIcon}
        </button>
    ) : (
        <a
            className={`${buttonStyles} ${gradient ? gradientStyles : ""} ${
                withEllipses
                    ? `${withEllipsesStyles} ${styles.ellipsesText}`
                    : ""
            }`}
            type={type}
            id={id}
            onClick={onClick}
            style={customStyle}
            href={rest.to}
            {...rest}
        >
            {StartIcon && StartIcon}
            {withEllipses ? (
                <>
                    {content}
                    <div className={styles.ellipsesContainer}>
                        {RedEllipseSmallLight}
                        {RedEllipseSmallDark}
                    </div>
                </>
            ) : (
                content
            )}
            {EndIcon && EndIcon}
        </a>
    );
};

Button.defaultProps = {
    type: "button",
    disabled: false,
    LoadingIndicator: <>Loading...</>,
};

Button.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    customStyle: PropTypes.object,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    StartIcon: PropTypes.node,
    EndIcon: PropTypes.node,
    isLoading: PropTypes.bool,
    LoadingIndicator: PropTypes.node,
    gradient: PropTypes.bool,
    withEllipses: PropTypes.bool,
};

export default Button;
