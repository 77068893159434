import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({
    alt,
    src,
    id,
    width,
    height,
    placeholderSrc,
    customStyle,
    ...props
}) => {
    return (
        <LazyLoadImage
            alt={alt}
            src={src}
            id={id}
            width={width}
            height={height}
            loading="lazy"
            placeholderSrc={placeholderSrc}
            style={customStyle}
            {...props}
        />
    );
};

Image.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
    id: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholderSrc: PropTypes.string,
    customStyle: PropTypes.object,
};

Image.defaultProps = {
    alt: "Image",
    placeholderSrc: "",
    customStyle: {},
};

export default Image;
