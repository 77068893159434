import { marqueeConfig } from "./config";
import styles from "./style.module.scss";
import { getProperImageUrl } from "Utils/Helpers";

const MarqueeText = ({ text }) => {
  return (
    <article className={styles.textContainer}>
      <div className={styles.circle}>
        <div className={styles.circleTwo}>
          <img src={getProperImageUrl("4/meetusvr-logo-3d.svg")} alt="coin" />
        </div>
      </div>
      <p className={styles.marqueeText}>{text}</p>
    </article>
  );
};

const MeetusvrTopMarquee = () => {
  return (
    <section className={styles.marqueeContainer}>
      <main className={styles.marquee}>
        <div className={styles.marqueeGroup}>
          {marqueeConfig.content.map((text, i) => (
            <span key={i}>
              <MarqueeText text={text} />
            </span>
          ))}
        </div>
      </main>
      <div className={styles.wave} />
      {/* <div className={styles.redEllipse} />
      <div className={styles.movEllipse} /> */}
    </section>
  );
};

export default MeetusvrTopMarquee;
