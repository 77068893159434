import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Navbar from "Components/Navbar";
import Footer from "Components/Footer";

import styles from "./style.module.scss";
import MeetusvrTopMarquee from "Components/MeetusvrTopMarquee";

// main components

export default function Root() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <section className={styles.layout}>
      <MeetusvrTopMarquee />
      <header className={styles.position} data-testid={"app-navbar"}>
        <Navbar />
      </header>

      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer />
    </section>
  );
}
