import { Link } from "react-router-dom";

import styles from "./style.module.scss";

const FeaturesDropdown = ({ pages }) => {
    return (
        <ul className={styles.featuresDropdown}>
            {pages?.map(({ title, content, path }) => (
                <li key={path} className={styles.item}>
                    <Link
                        to={path}
                        name={`pageLink-${path}`}
                        aria-label={title}
                        title={title}
                        className={styles.title}
                    >
                        {title}
                    </Link>
                    <p className={styles.content}>{content}</p>
                </li>
            ))}
        </ul>
    );
};

export default FeaturesDropdown;
