/**
 * A utility class for managing data in sessionStorage. Data is stored as key-value pairs, where the key is a string and the value is a string or object.
 * If an object is provided as the value, it will be converted to a string using JSON.stringify().
 * @category Services > Storage > Session Storage Manager Service
 * @class SessionStorageManager
 * @example
 * const sessionStorage = new SessionStorageManager(); // Creates a new instance of SessionStorageManager.
 */
class SessionStorageManager {
    /**
     * Retrieves the value associated with the provided key from sessionStorage.
     * @param {string} key - The key to retrieve the value for.
     * @returns {any} The value associated with the key, or null if the key does not exist.
     */
    get(key) {
        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    /**
     * Sets the value associated with the provided key in sessionStorage.
     * @param {string} key - The key to set the value for.
     * @param {any} value - The value to store in sessionStorage.
     */
    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Removes the value associated with the provided key from sessionStorage.
     * @param {string} key - The key to remove from sessionStorage.
     */
    remove(key) {
        sessionStorage.removeItem(key);
    }

    /**
     * Clears all values stored in sessionStorage.
     */
    clear() {
        sessionStorage.clear();
    }
}

export default SessionStorageManager;
