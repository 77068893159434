import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./style.module.scss";

const RenderedMenu = ({ links, linkType, socialMediaLinks }) => {
    return (
        <ul
            className={`${styles.links} ${
                !socialMediaLinks && styles.textLink
            }`}
        >
            {links?.map(({ id, path, Icon, text }) => (
                <li key={id} className={styles.link}>
                    {linkType === "internal" ? (
                    <Link
                        to={path}
                        name={`internalLink-${id}`}
                        aria-label={`Visit ${text} on ${path}`}
                        title={text}
                    >
                        {Icon && <Icon />}
                        {!socialMediaLinks && (
                            <span className={styles.text}>{text}</span>
                        )}
                    </Link>
                    ) : (
                    <a
                            href={path}
                            name={`externalLink-${id}`}
                            aria-label={`Visit ${text} on ${path}`}
                            title={text}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {Icon && <Icon />}
                            <span className={styles.text}>{text}</span>
                        </a>
                    )}
                </li>
            ))}
        </ul>
    );
};

RenderedMenu.propTypes = {
    links: PropTypes.array,
    linkType: PropTypes.string,
};

const Menu = ({ menu }) => {
    return (
        <div
            className={`${styles.menu} ${
                menu?.socialMediaLinks ? styles.socialMediaLinks : ""
            }`}
        >
            <p className={styles.title}>{menu?.title}</p>
            {menu?.menuLinks?.length > 1 ? (
                <div className={styles.multiMenu}>
                    {menu?.menuLinks?.map((links, i) => (
                        <RenderedMenu
                            key={links[0].text}
                            links={links}
                            linkType={menu?.linkType}
                            socialMediaLinks={menu?.socialMediaLinks}
                        />
                    ))}
                </div>
            ) : (
                <RenderedMenu
                    links={menu?.menuLinks?.[0]}
                    linkType={menu?.linkType}
                    socialMediaLinks={menu?.socialMediaLinks}
                />
            )}
        </div>
    );
};

Menu.propTypes = {
    menu: PropTypes.object,
};

export default Menu;
