import API from "../../API/Backend";
import {removeUserData} from "./removeUserData";

/**
 * Logout user from the system and remove user data from local storage and redux store as well as from the API too.
 * @module Utils/Hooks/Global/logout
 * @category Global Hooks - Logout User Hook (logout)
 * @returns {Promise<void>} - Returns nothing (void)
 */
export const logout = async () => {
    try {
        const {status} = await API({ endpoint: "user/logout", method: "POST" });
        if (status === 200) {
            removeUserData();
        }
    } catch (err) {
        // suppress the logout process if error occurred while logging out user from the system.
        removeUserData();
    } finally {
        console.log("finally");
    }
}
