export const marqueeConfig = {
  content: [
    "Meetusvr -  New Future Shopping with AI",
    "Reducing Your Carbon Footprint with MeetUsVR",
    "Begin with us and be the first to experience it",
    "Meetusvr -  New Future Shopping with AI",
    "Reducing Your Carbon Footprint with MeetUsVR",
    "Begin with us and be the first to experience it",
    "Meetusvr -  New Future Shopping with AI",
    "Reducing Your Carbon Footprint with MeetUsVR",
    "Begin with us and be the first to experience it",
    "Meetusvr -  New Future Shopping with AI",
    "Reducing Your Carbon Footprint with MeetUsVR",
    "Begin with us and be the first to experience it",
  ],
};
