import { lazy } from "react";

// Main layout
import Root from "./Root";
import LazyWrapper from "./LazyWrapper";

// Configs for policy pages (privacy policy, terms of services, api terms of services).
import { privacyPolicyConfig } from "Pages/Policy/privacyPolicyConfig";
import { termsOfServicesConfig } from "Pages/Policy/termsOfServicesConfig";
import { apiTermsConfig } from "Pages/Policy/apiTermsConfig";

// Lazy load pages
const Home = lazy(() => import("../Pages/Home"));
const Policy = lazy(() => import("Pages/Policy"));
const Pricing = lazy(() => import("Pages/Pricing"));
const Influencers = lazy(() => import("Pages/Influencers"));
const DownloadApp = lazy(() => import("Pages/DownloadApp"));
// const Blog = lazy(() => import("Pages/Blog"));
// const BlogDetails = lazy(() => import("Pages/BlogDetails"));
// const Events = lazy(() => import("Pages/Events"));
const ContactUs = lazy(() => import("Pages/ContactUs"));
const Roadmap = lazy(() => import("Pages/Roadmap"));
const NotFound = lazy(() => import("Pages/NotFound/NotFound"));
const ThreeSixtyStoreExperience = lazy(() =>
    import("Pages/Features/ThreeSixtyStoreExperience")
);
const VirtualSales = lazy(() => import("Pages/Features/VirtualSales"));
const InfluencerOpportunities = lazy(() =>
    import("Pages/Features/InfluencerOpportunities")
);
const DataDriven = lazy(() => import("Pages/Features/DataDriven"));
const Login = lazy(() => import("Pages/Auth/Login"));
const Register = lazy(() => import("Pages/Auth/Register"));
const PasswordRecovery = lazy(() => import("Pages/Auth/PasswordRecovery"));
const OTPAuthentication = lazy(() => import("Pages/Auth/OTPAuthentication"));
const ResetPassword = lazy(() => import("Pages/Auth/ResetPassword"));

// Lazy load routers
const AuthRoutes = lazy(() => import("./routes/AuthRoutes"));
const ProtectedRoutes = lazy(() => import("./routes/ProtectedRoutes"));
const AdminRoutes = lazy(() => import("./routes/AdminRoutes"));

export const PATHS = [
    {
        path: "/",
        element: (
            <LazyWrapper>
                <Root />
            </LazyWrapper>
        ),
        errorElement: <NotFound />,
        children: [
            { index: true, element: <Home /> },
            {
                path: "privacy-policy",
                element: <Policy config={privacyPolicyConfig} />,
            },
            {
                path: "terms-of-services",
                element: <Policy config={termsOfServicesConfig} />,
            },
            {
                path: "api-terms-of-services",
                element: <Policy config={apiTermsConfig} />,
            },
            {
                path: "pricing",
                element: <Pricing />,
            },
            {
                path: "influencers",
                element: <Influencers />,
            },
            {
                path: "download-app",
                element: <DownloadApp />,
            },
            {
                path: "resources",
                children: [
                    // {
                    //     path: "blogs",
                    //     element: <Blog />,
                    // },
                    // {
                    //     path: "blogs/:id",
                    //     element: <BlogDetails />,
                    // },
                    // {
                    //     path: "events",
                    //     element: <Events />,
                    // },
                    {
                        path: "roadmap",
                        element: <Roadmap />,
                    },
                ],
            },
            {
                path: "features",
                children: [
                    {
                        path: "360-store-experience",
                        element: <ThreeSixtyStoreExperience />,
                    },
                    {
                        path: "virtual-sales",
                        element: <VirtualSales />,
                    },
                    {
                        path: "influencer-opportunities",
                        element: <InfluencerOpportunities />,
                    },
                    {
                        path: "data-driven-optimization",
                        element: <DataDriven />,
                    },
                ],
            },
            {
                path: "contact-us",
                element: <ContactUs />,
            },
            {
                element: <ProtectedRoutes />,
                children: [
                    { path: "profile", element: <div>Profile Page</div> },
                ],
            },
            {
                element: <AdminRoutes />,
                children: [{ path: "admin", element: <div>Admin Page</div> }],
            },
            { path: "*", element: <NotFound /> },
        ],
    },
    {
        element: (
            <LazyWrapper>
                <AuthRoutes />
            </LazyWrapper>
        ),
        errorElement: <NotFound />,
        children: [
            { index: true, path: "login", element: <Login /> },
            { path: "register", element: <Register /> },
            { path: "password-recovery", element: <PasswordRecovery /> },
            { path: "otp-authentication", element: <OTPAuthentication /> },
            { path: "reset-password", element: <ResetPassword /> },
        ],
    },
];
